import React from "react"

import Content from "../card/content"
import Media from "../modules/media"
import Overlay from "../card/overlay"

import GrowHover from "../animation/grow-hover"

const BackgroundImage = props => {
  // React.useEffect(() => {
  //   console.log("=== BackgroundImage ===", props)
  // })

  return (
    <Media {...props}>
      <GrowHover enable={props.displayOverlay !== false ? false : true}>
        <Overlay {...props}>
          <Content {...props} />
        </Overlay>
      </GrowHover>
    </Media>
  )
}

export default BackgroundImage
