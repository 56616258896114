import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  Typography,
  Grid,
  Box,
  TextField,
  Link as StyleLink,
} from "@material-ui/core"

import Button from "../modules/button"
import CustomLink from "../modules/custom-link"
import Section from "../sections/section"
import CardBlock from "../blocks/card"

const Footer = () => {
  // React.useEffect(() => {
  //   console.log("=== Footer ===")
  // })

  const data = useStaticQuery(graphql`
    query {
      strapi {
        site {
          title
        }
        footers(sort: "order") {
          title
          flexibleContent {
            ... on Strapi_ComponentFooterContact {
              __typename
              title
              address
              email
            }
            ... on Strapi_ComponentFooterInternal {
              __typename
              title
              links {
                label
                page {
                  id
                  menu
                  paths
                  parentPages {
                    id
                    menu
                    parentPages {
                      id
                      menu
                      parentPages {
                        id
                        menu
                      }
                    }
                  }
                }
              }
            }
            ... on Strapi_ComponentFooterExternal {
              __typename
              id
              title
              links {
                label
                type
                url
              }
            }
            ... on Strapi_ComponentFooterNewsletter {
              __typename
              title
            }
          }
        }
      }
    }
  `)

  const FooterMenu = props => (
    <CardBlock width="SIZE_25" background="SECONDARY" transparent={true}>
      {props.title && (
        <Box marginLeft={2}>
          <Typography gutterBottom variant="h4">
            {props.title}
          </Typography>
        </Box>
      )}

      {props.children}
    </CardBlock>
  )

  return (
    <React.Fragment>
      {data.strapi?.footers?.map((footer, index) => (
        <React.Fragment key={index}>
          <Section title={footer.title} background="SECONDARY" padding={true}>
            {footer.flexibleContent?.map((contentItem, index) => (
              <React.Fragment key={index}>
                {contentItem.__typename === "Strapi_ComponentFooterContact" && (
                  <FooterMenu title={contentItem.title}>
                    <Box paddingX={2} paddingY={0}>
                      <Grid container spacing={0}>
                        {contentItem.address && (
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              dangerouslySetInnerHTML={{
                                __html: contentItem.address,
                              }}
                            />
                          </Grid>
                        )}
                        {contentItem.email && (
                          <Grid item xs={12}>
                            <StyleLink
                              href={"mailto:" + contentItem.email}
                              style={{ textDecoration: "none" }}
                            >
                              <Button
                                linkLabel="Ecrire un email"
                                background="SECONDARY"
                                type="CONTACT"
                              />
                            </StyleLink>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </FooterMenu>
                )}
                {contentItem.__typename ===
                  "Strapi_ComponentFooterInternal" && (
                  <FooterMenu title={contentItem.title}>
                    <Box paddingY={1}>
                      <Grid container spacing={1}>
                        {contentItem.links?.length > 0 &&
                          contentItem.links.map(link => (
                            <React.Fragment key={link.page.id}>
                              <Grid item xs={12}>
                                <Button
                                  linkLabel={link.label || link.page.menu}
                                  background="SECONDARY"
                                  type="PAGE"
                                  borderLess
                                  url={link.page.paths[0].slug}
                                />
                              </Grid>
                            </React.Fragment>
                          ))}
                      </Grid>
                    </Box>
                  </FooterMenu>
                )}
                {contentItem.__typename ===
                  "Strapi_ComponentFooterExternal" && (
                  <FooterMenu title={contentItem.title}>
                    <Box paddingY={1}>
                      <Grid container spacing={1}>
                        {contentItem.links?.length > 0 &&
                          contentItem.links.map((link, index) => (
                            <React.Fragment key={index}>
                              <Grid item xs={12}>
                                <Button
                                  linkLabel={link.label}
                                  background="SECONDARY"
                                  type={link.type}
                                  borderLess
                                  url={link.url}
                                />
                              </Grid>
                            </React.Fragment>
                          ))}
                      </Grid>
                    </Box>
                  </FooterMenu>
                )}
                {contentItem.__typename ===
                  "Strapi_ComponentFooterNewsletter" && (
                  <FooterMenu title={contentItem.title}>
                    <Box padding={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            placeholder="Votre nom"
                            variant="outlined"
                            size="small"
                            InputProps={{ style: { backgroundColor: "white" } }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            placeholder="Votre email"
                            variant="outlined"
                            size="small"
                            InputProps={{ style: { backgroundColor: "white" } }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            linkLabel="Envoyer"
                            background="SECONDARY"
                            type="SEND"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </FooterMenu>
                )}
              </React.Fragment>
            ))}
          </Section>
        </React.Fragment>
      ))}

      <Section background="DARK" padding={true}>
        <Grid item xs={12}>
          <Typography align="center">
            {`Copyright © ${data.strapi?.site?.title} - Création web `}
            <small>2021</small>
            {` : `}
            <CustomLink url="https://www.guslyon.fr">GusLyon.fr</CustomLink>
          </Typography>
        </Grid>
      </Section>
    </React.Fragment>
  )
}

export default Footer
