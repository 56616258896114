import React from "react"
import { useStaticQuery, graphql, Link as RouterLink } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Logo = () => {
  // React.useEffect(() => {
  //   console.log("=== Logo ===")
  // })

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { regex: "/logo.png/" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, placeholder: BLURRED, height: 64)
        }
      }
    }
  `)

  return (
    <RouterLink to="/">
      <GatsbyImage
        image={data?.file?.childImageSharp?.gatsbyImageData}
        alt=""
      />
    </RouterLink>
  )
}

export default Logo
