import React from "react"

import { Typography, Grid, Box } from "@material-ui/core"

import Button from "../modules/button"

import { useColor } from "../functions/use-color"

const Content = props => {
  // React.useEffect(() => {
  //   console.log("=== Content ===", props)
  // })

  const headingColor = {
    DARK: useColor(props.background)?.text,
    PRIMARY: useColor(props.background)?.titlePrimary,
    SECONDARY: useColor(props.background)?.titleSecondary,
  }

  return (
    <Grid container spacing={2}>
      {props.displayTitle !== false && props.title && (
        <Grid item xs={12}>
          <Typography
            align={props.center ? "center" : "left"}
            variant="h3"
            style={{
              color: headingColor[props.titleColor],
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "2",
            }}
          >
            {props.title}
          </Typography>
        </Grid>
      )}

      {props.displaySubtitle !== false && props.subtitle && (
        <Grid item xs={12}>
          <Typography
            align={props.center ? "center" : "left"}
            variant="h6"
            style={{
              color: headingColor[props.subtitleColor],
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "2",
            }}
          >
            {props.subtitle}
          </Typography>
        </Grid>
      )}

      {props.displayDescription !== false && props.description && (
        <Grid item xs={12}>
          <Box
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "2",
              textAlign: props.center ? "center" : "justify",
            }}
          >
            <Typography variant="body2">{props.description}</Typography>
          </Box>
        </Grid>
      )}

      {props.body && (
        <Grid item xs={12}>
          <Box
            style={{
              textAlign: props.center ? "center" : "justify",
            }}
          >
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{ __html: props.body }}
            />
          </Box>
        </Grid>
      )}

      {props.children && (
        <Grid item xs={12}>
          {props.children}
        </Grid>
      )}

      {props.displayLink !== false && props.linkLabel && props.url && (
        <Grid item xs={12}>
          <Typography align={props.center ? "center" : "justify"}>
            <Button {...props} url={null} />
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default Content
