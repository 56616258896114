import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link as RouterLink } from "gatsby"

import { Scrollbars } from "react-custom-scrollbars"

import { useTheme } from "@emotion/react"

import { makeStyles } from "@material-ui/core/styles"

import {
  Box,
  Drawer,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Link as StyleLink,
} from "@material-ui/core"

import {
  Menu as MenuIcon,
  Close as CloseIcon,
  ArrowForward as LinkIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@material-ui/icons"

import Logo from "../layout/logo"

const MobileMenu = props => {
  // React.useEffect(() => {
  //   console.log("=== Mobile Menu ===", props)
  // })

  const data = useStaticQuery(graphql`
    fragment MenuData on Strapi_Page {
      displayMenu
      id
      menu
      order
      slug
    }

    query {
      strapi {
        pages(sort: "order", where: { displayMenu: true }) {
          ...MenuData
          parentPages {
            ...MenuData
            parentPages {
              ...MenuData
            }
          }
          childPages(sort: "order", where: { displayMenu: true }) {
            ...MenuData
            childPages(sort: "order", where: { displayMenu: true }) {
              ...MenuData
            }
          }
        }
      }
    }
  `)

  const { strapi } = data

  const pages = strapi.pages
  const menuPages =
    pages && pages.filter(page => page.parentPages?.length === 0)
  // console.log("menuPages", menuPages)

  const customTheme = require(`../data/${process.env.GATSBY_PROJECT}/theme`)

  const theme = useTheme()
  const useStyles = makeStyles({
    listItem: {
      padding: 0,
      ...theme.typography.body2,
      transition: "all 100ms ease-in-out",
      color: customTheme?.color.menu.text,
      "&:hover": {
        backgroundColor: customTheme?.color.menu.active.background,
        color: customTheme?.color.menu.active.text,
      },
    },
    itemLink: {
      color: customTheme?.color.menu.text,
      fontFamily: customTheme?.font.menu + " !important",
      "&:hover": {
        color: customTheme?.color.menu.active.text,
        textDecoration: "none",
      },
    },
  })
  const classes = useStyles()

  const [openDrawer, setOpenDrawer] = React.useState(false)
  const toggleDrawer = () => setOpenDrawer(!openDrawer)

  const [openItems, setOpenItems] = React.useState(props.idPath || [])
  // console.log(openItems)

  const handleOpenMenu = id => e => {
    // console.log("handleOpenMenu", id)
    if (!openItems.includes(id)) {
      setOpenItems([...openItems, id])
    }
    toggleDrawer()
  }
  const toggleOpenItems = id => e => {
    // console.log("toggleOpenItems", id)
    if (openItems.includes(id)) {
      setOpenItems(openItems.filter(menuId => menuId !== id))
    } else {
      setOpenItems([...openItems, id])
    }
  }

  const MenuLinks = menu =>
    menu.pages?.length > 0 &&
    menu.pages.map(page => {
      const path = (menu.path || "") + "/" + page.slug
      const isActive = props.idPath?.includes(page.id)
      const isOpen = openItems.includes(page.id)
      return (
        <React.Fragment key={page.id}>
          <Box
            style={{
              backgroundColor: isActive
                ? customTheme?.color.menu.active.background
                : "inherit",
            }}
          >
            <ListItem button className={classes.listItem}>
              <StyleLink
                component={RouterLink}
                to={path}
                className={classes.itemLink}
                onClick={handleOpenMenu(page.id)}
              >
                <Box
                  paddingLeft={2 + menu.padding}
                  display="flex"
                  alignItems="center"
                  width="260px"
                  paddingY={1}
                  color={
                    isActive ? customTheme?.color.menu.active.text : "inherit"
                  }
                >
                  <ListItemIcon style={{ minWidth: "32px", color: "inherit" }}>
                    <LinkIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2" noWrap>
                      <b>{page.menu}</b>
                    </Typography>
                  </ListItemText>
                </Box>
              </StyleLink>
              {page.childPages?.length > 0 && (
                <IconButton size="small" onClick={toggleOpenItems(page.id)}>
                  {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              )}
            </ListItem>
          </Box>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <MenuLinks
              pages={page.childPages}
              padding={menu.padding + 4}
              path={path}
            />
          </Collapse>
        </React.Fragment>
      )
    })

  return (
    <React.Fragment>
      <Box onClick={toggleDrawer}>
        <IconButton
          css={{
            color: customTheme?.color.menu.text,
            "&:hover": {
              color: customTheme?.color.menu.active.text,
              backgroundColor: customTheme?.color.menu.active.background,
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box style={{ backgroundColor: customTheme?.color.menu.background }}>
          <Box
            onClick={toggleDrawer}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="80px"
          >
            <Box marginLeft={3}>
              <Logo />
            </Box>

            <Box marginRight={1}>
              <IconButton
                css={{
                  color: customTheme?.color.menu.text,
                  "&:hover": {
                    color: customTheme?.color.menu.active.text,
                    backgroundColor: customTheme?.color.menu.active.background,
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <Scrollbars
            autoHide
            style={{ width: "300px", height: "calc(100vh - 80px)" }}
          >
            <List component="nav">
              <MenuLinks pages={menuPages} padding={1} />
            </List>
          </Scrollbars>
        </Box>
      </Drawer>
    </React.Fragment>
  )
}

export default MobileMenu
