import { useMediaQuery } from "@material-ui/core"

export const useResponsiveWidth = width => {
  const useMatchBreakpoint = breakpoint =>
    useMediaQuery(theme => theme.breakpoints.down(breakpoint))

  const xs = useMatchBreakpoint("xs")
  const sm = useMatchBreakpoint("sm")
  const md = useMatchBreakpoint("md")
  const lg = useMatchBreakpoint("lg")
  const xl = useMatchBreakpoint("xl")

  if (width === "SIZE_100") return 100

  if (width === "SIZE_80") {
    if (xs) return 100
    if (sm) return 50
    if (md) return 66.66
    if (lg) return 75
    if (xl) return 80
  }

  if (width === "SIZE_75") {
    if (xs) return 100
    if (sm) return 50
    if (md) return 66.66
    if (lg) return 75
    if (xl) return 75
  }

  if (width === "SIZE_66") {
    if (xs) return 100
    if (sm) return 50
    if (md) return 66.66
    if (lg) return 66.66
    if (xl) return 66.66
  }

  if (width === "SIZE_50") {
    if (xs) return 100
    if (sm) return 50
    if (md) return 50
    if (lg) return 50
    if (xl) return 50
  }

  if (width === "SIZE_33") {
    if (xs) return 100
    if (sm) return 50
    if (md) return 33.33
    if (lg) return 33.33
    if (xl) return 33.33
  }

  if (width === "SIZE_25") {
    if (xs) return 100
    if (sm) return 50
    if (md) return 33.33
    if (lg) return 25
    if (xl) return 25
  }

  if (width === "SIZE_20") {
    if (xs) return 100
    if (sm) return 50
    if (md) return 33.33
    if (lg) return 25
    if (xl) return 20
  } else return 100
}

export const useHeight = height => {
  const heights = {
    SIZE_20: 20 / 100,
    SIZE_25: 25 / 100,
    SIZE_33: 33.33 / 100,
    SIZE_50: 50 / 100,
    SIZE_66: 66.66 / 100,
    SIZE_75: 75 / 100,
    SIZE_80: 80 / 100,
    SIZE_100: 100 / 100,
  }
  return heights[height]
}
