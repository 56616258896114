import React from "react"
import { Link as RouterLink } from "gatsby"

import { useTheme } from "@emotion/react"

import { makeStyles } from "@material-ui/core/styles"
import { Button, Typography, Link as StyleLink } from "@material-ui/core"
import {
  OpenInNew as CustomIcon,
  ArrowForward as PageIcon,
  GetApp as DocumentIcon,
  ZoomOutMap as ImageIcon,
  PlayArrow as VideoIcon,
  MenuBook as ArticleIcon,
  LocationOn as LocationIcon,
  Event as EventIcon,
  FilterHdr as EnvironmentIcon,
  Group as EntityIcon,
  Pets as TaxonIcon,
  DateRange as ProgramIcon,
  //
  Restore as ResetIcon,
  Close as CloseIcon,
  Email as ContactIcon,
  Send as SendIcon,
  //
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
} from "@material-ui/icons"

import { useColor } from "../functions/use-color"
import { matchOneRegex } from "../functions/match-regex"

const ButtonModule = props => {
  // React.useEffect(() => {
  //   console.log("=== Button ===", props)
  // })

  const theme = useTheme()

  const useStyles = makeStyles({
    button: {
      textTransform: "none",
      color: useColor(props.background)?.link,
      borderColor: useColor(props.background)?.link,
      // backgroundColor: useColor(props.background)?.link,
      borderRadius: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      "&:hover": {
        color: useColor(props.background)?.linkHover,
        backgroundColor: useColor(props.background)?.linkBackgroundHover,
        borderColor: useColor(props.background)?.linkBackgroundHover,
      },
      maxWidth: "100%",
    },
  })
  const classes = useStyles()

  const linkType = props.url
    ? matchOneRegex(props.url, ["http://", "https://", ".pdf", ".jpg"])
      ? "EXTERNAL"
      : "INTERNAL"
    : null

  return (
    <Button
      onClick={props.onClick}
      disabled={props.disabled}
      variant={props.borderLess ? null : "outlined"}
      className={classes.button}
      startIcon={
        <React.Fragment>
          {props.type === "CUSTOM" && <CustomIcon />}
          {props.type === "PAGE" && <PageIcon />}
          {props.type === "DOCUMENT" && <DocumentIcon />}
          {props.type === "IMAGE" && <ImageIcon />}
          {props.type === "VIDEO" && <VideoIcon />}
          {props.type === "ARTICLE" && <ArticleIcon />}
          {props.type === "LOCATION" && <LocationIcon />}
          {props.type === "EVENT" && <EventIcon />}
          {props.type === "ENVIRONMENT" && <EnvironmentIcon />}
          {props.type === "ENTITY" && <EntityIcon />}
          {props.type === "TAXON" && <TaxonIcon />}
          {props.type === "PROGRAM" && <ProgramIcon />}
          {/* ACTIONS */}
          {props.type === "RESET" && <ResetIcon />}
          {props.type === "CLOSE" && <CloseIcon />}
          {props.type === "CONTACT" && <ContactIcon />}
          {props.type === "SEND" && <SendIcon />}
          {/* SOCIALS */}
          {props.type === "FACEBOOK" && <FacebookIcon />}
          {props.type === "INSTAGRAM" && <InstagramIcon />}
          {props.type === "YOUTUBE" && <YouTubeIcon />}
          {props.type === "TWITTER" && <TwitterIcon />}
        </React.Fragment>
      }
      component={
        props.url ? (linkType === "INTERNAL" ? RouterLink : StyleLink) : "span"
      }
      to={linkType === "INTERNAL" ? props.url : null}
      href={linkType === "EXTERNAL" ? props.url : null}
      target={linkType === "EXTERNAL" ? "_blank" : null}
      rel={linkType === "EXTERNAL" ? "noopener noreferrer" : null}
    >
      <Typography
        noWrap
        variant="caption"
        style={{
          fontWeight: "bold",
        }}
      >
        {props.linkLabel}
      </Typography>
    </Button>
  )
}

export default ButtonModule
