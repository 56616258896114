import React from "react"

import fetch from "cross-fetch"
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
} from "@apollo/client"

// import { getStrapiQuerySchema } from "../data/graphql-queries"

// const schema = getStrapiQuerySchema({
//   queryBy: "gatsby",
// })
// console.log(schema)

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: process.env.GATSBY_STRAPI_HOST + "/graphql",
    fetch,
  }),
})

// eslint-disable-next-line react/display-name,react/prop-types
export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)
