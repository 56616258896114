import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { ThemeProvider } from "@emotion/react"
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles"

import {
  CssBaseline,
  AppBar,
  Toolbar,
  Container,
  Box,
  Hidden,
} from "@material-ui/core"

import DesktopMenu from "../layout/menu-desktop"
import MobileMenu from "../layout/menu-mobile"
import Logo from "../layout/logo"
import Footer from "../layout/footer"
import Progress from "../layout/progress"
import Search from "../layout/search"

const Layout = props => {
  // React.useEffect(() => {
  //   console.log("=== Layout Wrapper ===", props)
  // })

  const [fontFace, setFontFace] = React.useState(null)
  import(`../data/${process.env.GATSBY_PROJECT}/fonts/font-face`).then(result =>
    setFontFace(result.default)
  )

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { regex: "/background.jpg/" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const customTheme = require(`../data/${process.env.GATSBY_PROJECT}/theme`)

  let theme = createTheme({
    palette: {
      background: {
        lighten: customTheme?.color.background.lighten,
        darken: customTheme?.color.background.darken,
      },
      contrast: {
        light: customTheme?.color.text.light,
        dark: customTheme?.color.text.dark,
      },
      link: {
        light: customTheme?.color.action.light,
        main: customTheme?.color.action.main,
        dark: customTheme?.color.action.dark,
        contrastText: customTheme?.color.text.light,
      },
      primary: {
        light: customTheme?.color.primary.light,
        main: customTheme?.color.primary.main,
        dark: customTheme?.color.primary.dark,
        contrastText: customTheme?.color.text.light,
      },
      secondary: {
        light: customTheme?.color.secondary.light,
        main: customTheme?.color.secondary.main,
        dark: customTheme?.color.secondary.dark,
        contrastText: customTheme?.color.text.light,
      },
    },
    typography: {
      htmlFontSize: 18,
      fontSize: 18,
      fontFamily: customTheme?.font.body,
      h1: {
        fontFamily: customTheme?.font.title,
        fontSize: "2.5rem",
        lineHeight: 1.4,
      },
      h2: {
        fontFamily: customTheme?.font.title,
        fontSize: "2rem",
        lineHeight: 1.4,
      },
      h3: {
        fontFamily: customTheme?.font.title,
        fontSize: "1.75rem",
        lineHeight: 1.4,
      },
      h4: {
        fontFamily: customTheme?.font.title,
        fontSize: "1.5rem",
        lineHeight: 1.4,
      },
      h5: {
        fontFamily: customTheme?.font.title,
        fontSize: "1.375rem",
        lineHeight: 1.4,
      },
      h6: {
        fontFamily: customTheme?.font.title,
        fontSize: "1.25rem",
        lineHeight: 1.4,
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          html: {
            overflowX: "hidden",
          },
          body: {
            paddingRight: "0px !important", // Fix lightbox
          },
          "@font-face": fontFace,
        },
      },
      MuiLink: {
        root: {
          color: customTheme?.color.action.main,
        },
      },
    },
  })
  theme = responsiveFontSizes(theme)

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <AppBar
          position="sticky"
          color="transparent"
          style={{ backgroundColor: customTheme?.color.menu.background }}
        >
          <Progress />

          <Container>
            <Toolbar disableGutters style={{ height: "80px" }}>
              <Box marginRight={4}>
                <Logo />
              </Box>

              <Box display="flex" flexGrow={1} />

              <Hidden smDown>
                <DesktopMenu idPath={props.pageContext.idPath} />
              </Hidden>

              <Search />

              <Hidden mdUp>
                <MobileMenu idPath={props.pageContext.idPath} />
              </Hidden>
            </Toolbar>
          </Container>
        </AppBar>

        <BackgroundImage
          fluid={data?.file?.childImageSharp?.fluid}
          style={{
            backgroundRepeat: "repeat",
            backgroundSize: "auto",
            backgroundAttachment: "fixed",
          }}
          preserveStackingContext
        >
          {props.children}
        </BackgroundImage>

        <Footer />
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

export default Layout
