import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import { Typography, Box, Grid } from "@material-ui/core"

const Media = props => {
  // React.useEffect(() => {
  //   console.log("=== Media ===", props)
  // })

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { regex: "/default.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `)

  const imageFluid = props.imageFluid || data.file.childImageSharp.fluid

  const imageRatio = {
    ORIGINAL: imageFluid.aspectRatio,
    LANDSCAPE: 16 / 9,
    SQUARE: 1 / 1,
  }
  const aspectRatio = imageRatio[props.imageRatio] || imageRatio["LANDSCAPE"]

  return (
    <Grid item style={{ height: "100%" }}>
      <Box position="relative" height="100%" display="flex" alignItems="center">
        {imageFluid && aspectRatio ? (
          <Img
            fluid={{
              ...imageFluid,
              aspectRatio: aspectRatio,
            }}
            style={{
              width: "100%",
              height: props.imageRatio === "ORIGINAL" ? "auto" : "100%",
              color: "white",
              minHeight: "100px",
            }}
          />
        ) : (
          <Img
            fluid={{
              src: props.imageUrl,
              aspectRatio: aspectRatio,
            }}
            style={{
              width: "100%",
              height: "100%",
              color: "white",
              minHeight: "100px",
            }}
          />
        )}
        {props.children && (
          <Box position="absolute" top={0} bottom={0} left={0} right={0}>
            {props.children}
          </Box>
        )}
        {props.displayCaption && props.imageCaption && (
          <Box position="absolute" right={8} bottom={6}>
            <Typography variant="caption" style={{ color: "white" }}>
              {props.imageCaption}
            </Typography>
          </Box>
        )}
      </Box>
    </Grid>
  )
}

export default Media
