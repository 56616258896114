import React from "react"

import { useTheme } from "@emotion/react"

import { Box } from "@material-ui/core"

import Content from "../card/content"
import Media from "../modules/media"

const VerticalImage = props => {
  // React.useEffect(() => {
  //   console.log("=== VerticalImage ===")
  // })

  const theme = useTheme()

  return (
    <Box>
      <Box overflow="hidden">
        <Media {...props} />
      </Box>
      <Box
        padding={props.padding ? theme.spacing(0.5) : 0}
        paddingTop={theme.spacing(0.5)}
      >
        <Content {...props} />
      </Box>
    </Box>
  )
}

export default VerticalImage
