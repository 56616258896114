import React from "react"

import { useTheme } from "@emotion/react"

import { Box, Grid, Container, Divider, Typography } from "@material-ui/core"

import { useColor } from "../functions/use-color"

const Section = props => {
  // React.useEffect(() => {
  //   console.log("=== Section ===", props)
  // })

  const theme = useTheme()

  const headingColor = {
    DARK: useColor(props.background)?.text,
    PRIMARY: useColor(props.background)?.titlePrimary,
    SECONDARY: useColor(props.background)?.titleSecondary,
  }

  const PaddingContainer = props => (
    <React.Fragment>
      {props.padding ? (
        <Container style={{ height: "100%" }}>{props.children}</Container>
      ) : (
        props.children
      )}
    </React.Fragment>
  )

  return (
    <Box
      paddingY={props.padding ? 10 : 0}
      style={{
        color: useColor(props.background)?.text,
        backgroundColor: useColor(props.background)?.background,
      }}
    >
      <PaddingContainer {...props}>
        <Grid
          container
          spacing={props.padding ? 4 : 0}
          justifyContent="center"
          alignItems="flex-start"
          style={{ height: "100%" }}
        >
          {props.title && (
            <Grid item xs={12}>
              <Box
                paddingTop={props.padding ? 0 : 10}
                paddingBottom={props.padding ? 0 : 4}
              >
                <Typography
                  variant="h2"
                  align="center"
                  // noWrap
                  gutterBottom
                  style={{
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    color:
                      headingColor[props.titleColor] || headingColor["DARK"],
                  }}
                >
                  {props.title}
                </Typography>

                <Box display="flex" justifyContent="center">
                  <Divider
                    style={{
                      width: theme.spacing(10),
                      height: theme.spacing(0.5),
                      backgroundColor:
                        headingColor[props.titleColor] || headingColor["DARK"],
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          )}

          {props.intro && (
            <Grid item xs={12}>
              <Box style={{ textAlign: "justify" }}>
                <Typography dangerouslySetInnerHTML={{ __html: props.intro }} />
              </Box>
            </Grid>
          )}

          {props.children}
        </Grid>
      </PaddingContainer>
    </Box>
  )
}

Section.defaultProps = {
  anchorName: null,
  background: "TRANSPARENT",
  layout: "GRID",
  padding: true,
  title: null,
  titleColor: "PRIMARY",
  visibleItems: null,
}

export default Section
