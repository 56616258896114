import { useTheme } from "@emotion/react"

export const useColor = colorName => {
  const theme = useTheme()

  const colors = {
    DARK: {
      background: theme.palette.contrast.dark,
      text: theme.palette.contrast.light,
      titlePrimary: theme.palette.contrast.light,
      titleSecondary: theme.palette.contrast.light,
      link: theme.palette.contrast.light,
      linkHover: theme.palette.contrast.light,
      linkBackgroundHover: theme.palette.link.main,
    },
    LIGHT: {
      background: theme.palette.contrast.light,
      text: theme.palette.contrast.dark,
      titlePrimary: theme.palette.primary.main,
      titleSecondary: theme.palette.secondary.main,
      link: theme.palette.link.main,
      linkHover: theme.palette.contrast.light,
      linkBackgroundHover: theme.palette.link.main,
    },
    PRIMARY: {
      background: theme.palette.primary.main,
      text: theme.palette.contrast.light,
      titlePrimary: theme.palette.contrast.light,
      titleSecondary: theme.palette.contrast.light,
      link: theme.palette.contrast.light,
      linkHover: theme.palette.primary.main,
      linkBackgroundHover: theme.palette.contrast.light,
    },
    SECONDARY: {
      background: theme.palette.secondary.main,
      text: theme.palette.contrast.light,
      titlePrimary: theme.palette.contrast.light,
      titleSecondary: theme.palette.contrast.light,
      link: theme.palette.contrast.light,
      linkHover: theme.palette.secondary.main,
      linkBackgroundHover: theme.palette.contrast.light,
    },
    TRANSPARENT: {
      // background: theme.palette.background.darken,
      background: "transparent",
      text: theme.palette.contrast.dark,
      titlePrimary: theme.palette.primary.main,
      titleSecondary: theme.palette.secondary.main,
      link: theme.palette.link.main,
      linkHover: theme.palette.contrast.light,
      linkBackgroundHover: theme.palette.link.main,
    },
  }

  return colors[colorName]
}
