import React from "react"

import { useTheme } from "@emotion/react"

import { Box } from "@material-ui/core"

import { useColor } from "../functions/use-color"

const Overlay = props => {
  // React.useEffect(() => {
  //   console.log("=== Overlay ===", props)
  // })

  const theme = useTheme()

  const backgroundColor = useColor(props.background)?.background
  const textColor = useColor(props.background)?.text

  return (
    <React.Fragment>
      {!props.title && !props.subtitle && !props.description ? (
        props.children
      ) : (
        <Box
          padding={theme.spacing(0.5)}
          width="100%"
          height="100%"
          minHeight="200px"
          style={{
            background: `linear-gradient(transparent, ${backgroundColor})`,
            color: textColor,
          }}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="center"
          textAlign="center"
        >
          {props.children}
        </Box>
      )}
    </React.Fragment>
  )
}

export default Overlay
