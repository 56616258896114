import React from "react"

import { Box, Grow } from "@material-ui/core"

const GrowHover = props => {
  // React.useEffect(() => {
  //   console.log("=== GrowHover ===")
  // })

  const [hover, setHover] = React.useState(props.enable ? false : true)

  const handleHover = hover => e => {
    setHover(hover)
  }

  return (
    <React.Fragment>
      {props.enable ? (
        <Box
          onMouseEnter={handleHover(true)}
          onMouseLeave={handleHover(false)}
          height="100%"
        >
          <Grow in={hover}>
            <Box height="100%">{props.children}</Box>
          </Grow>
        </Box>
      ) : (
        <Box height="100%">{props.children}</Box>
      )}
    </React.Fragment>
  )
}

export default GrowHover
