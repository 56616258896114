import React from "react"
import { Link as RouterLink } from "gatsby"
import Img from "gatsby-image"

import { useTheme } from "@emotion/react"

import {
  Link as StyleLink,
  Box,
  Dialog,
  IconButton,
  Typography,
} from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"

import { matchOneRegex } from "../functions/match-regex"

import PopupVideo from "../modules/popup-video"

const CustomLink = props => {
  // React.useEffect(() => {
  //   console.log("=== CustomLink ===", props)
  // })

  const theme = useTheme()

  const [openImage, setOpenImage] = React.useState(false)
  const handleOpenImage = () => {
    setOpenImage(true)
  }
  const handleCloseImage = () => {
    setOpenImage(false)
  }

  const style = {
    cursor: "pointer",
    color: "inherit",
    // fontWeight: "bold",
    ...props.style,
  }

  return (
    <React.Fragment>
      {matchOneRegex(props.url, ["http://", "https://", ".pdf"]) ? (
        // EXTERNAL LINK
        <StyleLink
          href={props.url}
          target="_blank"
          rel="noopener noreferrer"
          style={style}
        >
          {props.children}
        </StyleLink>
      ) : // IMAGE LIGHTBOX
      matchOneRegex(props.url, [".jpg"]) ? (
        <Box onClick={handleOpenImage} style={style}>
          {props.children}
        </Box>
      ) : // VIDEO LIGHTBOX
      props.url === "#" ? (
        <PopupVideo
          title={props.title}
          platform={props.platform}
          code={props.code}
        >
          {props.children}
        </PopupVideo>
      ) : (
        // INTERNAL PAGE
        <StyleLink component={RouterLink} to={props.url} style={style}>
          {props.children}
        </StyleLink>
      )}

      <Dialog
        open={openImage}
        onClose={handleCloseImage}
        fullWidth={true}
        maxWidth={props.imageFluid ? false : "lg"}
        PaperProps={{
          square: true,
          style: {
            maxWidth: props.imageFluid
              ? props.imageFluid.presentationWidth
              : "inherit",
          },
        }}
      >
        {props.imageFluid && (
          <Box onClick={handleCloseImage}>
            <Img
              fluid={props.imageFluid}
              imgStyle={{ objectFit: "contain" }}
              style={{ maxHeight: `calc(100vh - ${theme.spacing(8)}px)` }}
            />
          </Box>
        )}

        {props.imageCaption && (
          <Box position="absolute" right={8} bottom={6}>
            <Typography variant="caption" style={{ color: "white" }}>
              {props.imageCaption}
            </Typography>
          </Box>
        )}

        <IconButton
          style={{
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: "white",
            // color: theme.palette.grey[500],
            zIndex: 1,
          }}
          onClick={handleCloseImage}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
    </React.Fragment>
  )
}

export default CustomLink
