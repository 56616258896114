import React from "react"

import { Box, LinearProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const customTheme = require(`../data/${process.env.GATSBY_PROJECT}/theme`)

const useStyles = makeStyles(theme => ({
  linearColorPrimary: {
    backgroundColor: customTheme.color.progress.background,
  },
  linearBarColorPrimary: {
    backgroundColor: customTheme.color.progress.foreground,
  },
}))

const Progress = () => {
  // React.useEffect(() => {
  //   console.log("=== Progress ===")
  // })

  const classes = useStyles()

  // Simulate loading
  let [loading, setLoading] = React.useState(0)
  React.useEffect(() => {
    if (loading < 100) {
      // console.log(loading)
      let id = setInterval(() => {
        setLoading(loading + 10)
      }, 1)
      return () => clearInterval(id)
    }
  })

  return (
    <Box position="absolute" width="100%" zIndex={1}>
      <LinearProgress
        classes={{
          colorPrimary: classes.linearColorPrimary,
          barColorPrimary: classes.linearBarColorPrimary,
        }}
        // className={classes.progress}
        variant={"determinate"}
        value={loading}
      />
    </Box>
  )
}

export default Progress
