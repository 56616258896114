import React from "react"

import { useTheme } from "@emotion/react"

import { Grid, Box } from "@material-ui/core"

import Content from "../card/content"
import Media from "../modules/media"

import { useResponsiveWidth } from "../functions/use-sizes"

const HorizontalImage = props => {
  // React.useEffect(() => {
  //   console.log("=== HorizontalImage ===")
  // })

  const theme = useTheme()

  const imageWidth = useResponsiveWidth(props.imageWidth)
  const contentWidth = imageWidth === 100 ? 100 : 100 - imageWidth

  return (
    <Grid
      container
      spacing={4}
      direction={props.imagePosition === "RIGHT" ? "row-reverse" : "row"}
    >
      <Grid item style={{ width: `${imageWidth}%` }}>
        <Box overflow="hidden" height="100%">
          <Media {...props} />
        </Box>
      </Grid>
      <Grid item style={{ width: `${contentWidth}%` }}>
        <Box
          padding={props.padding ? theme.spacing(0.5) : 0}
          paddingLeft={
            (!props.padding && props.imagePosition === "RIGHT") ||
            props.imagePosition === "LEFT"
              ? 0
              : theme.spacing(0.5)
          }
          paddingRight={
            (!props.padding && props.imagePosition === "LEFT") ||
            props.imagePosition === "RIGHT"
              ? 0
              : theme.spacing(0.5)
          }
        >
          <Content {...props} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default HorizontalImage
