import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link as RouterLink } from "gatsby"

import { useTheme } from "@emotion/react"

import { makeStyles } from "@material-ui/core/styles"

import { Box, Popper, MenuItem, Fade, Button } from "@material-ui/core"

const DesktopMenu = props => {
  // React.useEffect(() => {
  //   console.log("=== Desktop Menu ===", props)
  // })

  const data = useStaticQuery(graphql`
    fragment MenuData on Strapi_Page {
      displayMenu
      id
      menu
      order
      slug
    }

    query {
      strapi {
        pages(sort: "order", where: { displayMenu: true }) {
          ...MenuData
          parentPages {
            ...MenuData
            parentPages {
              ...MenuData
            }
          }
          childPages(sort: "order", where: { displayMenu: true }) {
            ...MenuData
            childPages(sort: "order", where: { displayMenu: true }) {
              ...MenuData
            }
          }
        }
      }
    }
  `)

  const { strapi } = data

  const pages = strapi.pages
  const menuPages =
    pages && pages.filter(page => page.parentPages?.length === 0)

  const customTheme = require(`../data/${process.env.GATSBY_PROJECT}/theme`)

  const theme = useTheme()
  const useStyles = makeStyles({
    tab: {
      height: theme.spacing(10),
      opacity: 1,
      padding: theme.spacing(3),
      minWidth: "initial",
      textTransform: "none",
      fontWeight: "bold !important",
      fontFamily: customTheme?.font.menu + " !important",
      borderRadius: 0,
      "&:hover": {
        borderBottom: `2px solid ${customTheme?.color.menu.active.text}`,
      },
    },
    link: {
      ...theme.typography.body2,
      transition: "all 100ms ease-in-out",
      color: customTheme?.color.menu.text,
      fontFamily: customTheme?.font.menu + " !important",
      "&:hover": {
        backgroundColor: customTheme?.color.menu.active.background,
        color: customTheme?.color.menu.active.text,
      },
    },
  })
  const classes = useStyles()

  const [openItem, setOpenItem] = React.useState(null)

  const [oneAnchor, setOneAnchor] = React.useState(null)

  const handleOpen = id => e => {
    // console.log("handleOpen()", id)
    setOpenItem(id)
    setOneAnchor(e.currentTarget)
  }

  const handleClose = () => {
    // console.log("handleClose()")
    setOpenItem(null)
    setOneAnchor(null)
  }

  const DropMenuItem = props => (
    <MenuItem
      onClick={handleClose}
      className={classes.link}
      style={{
        paddingTop: props.padding,
        paddingBottom: props.padding,
        color: props?.active && customTheme?.color.menu.active.text,
        backgroundColor:
          props?.active && customTheme?.color.menu.active.background,
      }}
      component={RouterLink}
      to={props?.link}
    >
      <Box padding={0} paddingLeft={props?.parent ? 1 : 4} paddingRight={1}>
        {props?.parent ? <b>{props?.label}</b> : props?.label}
      </Box>
    </MenuItem>
  )

  return (
    <Box
      onMouseLeave={handleClose}
      height="100%"
      display="flex"
      flexWrap="nowrap"
    >
      {/* START : LEVEL 1 */}

      {menuPages?.length > 0 &&
        menuPages.map(page => {
          const isActive = props.idPath?.includes(page.id)
          const isOpen = openItem === page.id
          const path1 = "/" + page.slug
          return (
            <React.Fragment key={page.id}>
              <Button
                onClick={handleClose}
                onMouseEnter={handleOpen(page.id)}
                className={[classes.tab, classes.link].join(" ")}
                style={{
                  borderBottom:
                    isActive || isOpen
                      ? `2px solid ${customTheme?.color.menu.active.text}`
                      : `2px solid transparent`,
                  color:
                    isActive || isOpen
                      ? customTheme?.color.menu.active.text
                      : customTheme?.color.menu.text,
                  backgroundColor:
                    isActive || isOpen
                      ? customTheme?.color.menu.active.background
                      : customTheme?.color.menu.background,
                }}
                component={RouterLink}
                to={path1}
              >
                {page.menu}
              </Button>

              <Popper
                key={page.id}
                open={isOpen}
                anchorEl={oneAnchor}
                placement={"bottom-start"}
                id={"one-" + page.id}
                style={{
                  zIndex: 1,
                }}
              >
                <Fade in={true}>
                  <Box
                    boxShadow={4}
                    style={{
                      backgroundColor: customTheme?.color.menu.background,
                    }}
                  >
                    {/* START : LEVEL 2 */}

                    {page.childPages?.length > 0 && (
                      <Box paddingY={1} paddingX={0}>
                        {page.childPages.map(page => {
                          const isActive = props.idPath?.includes(page.id)
                          const path2 = path1 + "/" + page.slug

                          return (
                            <Box key={page.id}>
                              <DropMenuItem
                                label={page.menu}
                                link={path2}
                                active={isActive}
                                parent={true}
                                padding={theme.spacing(0.6)}
                              />

                              {/* START : LEVEL 3 */}

                              {page.childPages?.length > 0 &&
                                page.childPages.map(page => {
                                  const isActive = props.idPath?.includes(
                                    page.id
                                  )
                                  const path3 = path2 + "/" + page.slug

                                  return (
                                    <Box key={page.id}>
                                      <DropMenuItem
                                        label={page.menu}
                                        link={path3}
                                        active={isActive}
                                        parent={false}
                                        padding={theme.spacing(0.2)}
                                      />
                                    </Box>
                                  )
                                })}

                              {/* STOP : LEVEL 3 */}
                            </Box>
                          )
                        })}
                      </Box>
                    )}

                    {/* STOP : LEVEL 2 */}
                  </Box>
                </Fade>
              </Popper>
            </React.Fragment>
          )
        })}

      {/* STOP : LEVEL 1 */}
    </Box>
  )
}

export default DesktopMenu
