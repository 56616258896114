import React from "react"

import { Box, Dialog, IconButton } from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"

const PopupVideo = props => {
  // React.useEffect(() => {
  //   console.log("=== PopupVideo ===", props)
  // })

  const { title, platform, code, children } = props

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <Box onClick={handleOpen}>{children}</Box>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        PaperProps={{ sx: { minHeight: "200px" } }}
      >
        <IconButton
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        {/* YouTube */}
        {platform === "YOUTUBE" && (
          <iframe
            title={title}
            frameBorder="0"
            style={{
              width: "100%",
              height: "80vh",
              maxWidth: "1280px",
              maxHeight: "720px",
            }}
            src={`https://www.youtube-nocookie.com/embed/${code}?theme=dark&amp;autoplay=1&amp;autohide=0&amp;cc_load_policy=1&amp;modestbranding=1&amp;fs=0&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;mute=0&amp;loop=1;`}
            sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
          />
        )}
      </Dialog>
    </React.Fragment>
  )
}

export default PopupVideo
