import React from "react"

import { useTheme } from "@emotion/react"

import { Box } from "@material-ui/core"

import Content from "../card/content"

const Block = props => {
  // React.useEffect(() => {
  //   console.log("=== Block ===")
  // })

  const theme = useTheme()

  return (
    <Box>
      <Box padding={props.padding ? theme.spacing(0.5) : 0}>
        <Content {...props} />
      </Box>
    </Box>
  )
}

export default Block
