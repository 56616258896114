import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useFlexSearch } from "react-use-flexsearch"

import {
  Box,
  IconButton,
  Dialog,
  DialogContentText,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Typography,
} from "@material-ui/core"
import { Search as SearchIcon } from "@material-ui/icons"

import CustomLink from "../modules/custom-link"

const Search = () => {
  // React.useEffect(() => {
  //   console.log("=== Mobile Menu ===", props)
  // })

  const customTheme = require(`../data/${process.env.GATSBY_PROJECT}/theme`)

  const data = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `)
  const { index, store } = data?.localSearchPages
  // console.log(index, store)

  const [query, setQuery] = React.useState(null)
  // console.log("query", query)

  const results = useFlexSearch(query, index, store)
  // console.log("results", results)

  const [field, setField] = React.useState("")
  // console.log("field", field)

  const handleChangeField = e => {
    const { value } = e.target
    setField(value)
    // console.log("value", value, value.length)
    if (value.length > 2) {
      setQuery(value)
    } else {
      setQuery(null)
    }
  }

  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box>
      <IconButton
        onClick={handleClickOpen}
        css={{
          color: customTheme?.color.menu.text,
          "&:hover": {
            color: customTheme?.color.menu.active.text,
            backgroundColor: customTheme?.color.menu.active.background,
          },
        }}
      >
        <SearchIcon />
      </IconButton>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        scroll="body"
      >
        <Box padding={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="search-field">Recherche</InputLabel>
            <OutlinedInput
              id="search-field"
              value={field}
              onChange={handleChangeField}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={90}
              autoFocus
            />
          </FormControl>
        </Box>

        {field.length > 2 && (
          <Box padding={4} paddingTop={0}>
            <DialogContentText>Pages trouvées :</DialogContentText>
            {results.length > 0 ? (
              <ul>
                {results.map(result => (
                  <li key={result.id} onClick={handleClose}>
                    <Typography variant="body2" component="div">
                      <CustomLink url={result.path}>
                        <b>{result.title}</b>
                      </CustomLink>
                      {` `}
                      <CustomLink
                        url={result.path}
                        style={{
                          color: customTheme?.color.action.main,
                          marginLeft: "8px",
                        }}
                      >
                        <small>{result.path}</small>
                      </CustomLink>
                    </Typography>
                  </li>
                ))}
              </ul>
            ) : (
              <Typography variant="body2">
                Aucun résultat : précisez votre recherche...
              </Typography>
            )}
          </Box>
        )}
      </Dialog>
    </Box>
  )
}

export default Search
