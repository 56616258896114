import React from "react"

import { useTheme } from "@emotion/react"

import { makeStyles } from "@material-ui/core/styles"
import { Grid, Box, useMediaQuery } from "@material-ui/core"

import VerticalImage from "../card/media-vertical"
import HorizontalImage from "../card/media-horizontal"
import HiddenImage from "../card/media-hidden"
import BackgroundImage from "../card/media-background"
// import ScrollReveal from "../animation/scroll-reveal"

import CustomLink from "../modules/custom-link"
import { useResponsiveWidth } from "../functions/use-sizes"
import { useColor } from "../functions/use-color"

const Card = props => {
  // React.useEffect(() => {
  //   console.log("=== Card ===", props)
  // })

  const theme = useTheme()

  const HoverLink = props => {
    const useStyles = makeStyles({
      link: {
        transition: "box-shadow .1s linear",
        "&:hover": {
          boxShadow: theme.shadows[props.transparent ? 0 : 10],
          cursor: "pointer",
        },
        "& .gatsby-image-wrapper": {
          transition: "transform .2s linear",
        },
        "&:hover .gatsby-image-wrapper": {
          transform: "scale(1.05)",
        },
      },
    })
    const classes = useStyles()

    return props.url ? (
      <CustomLink {...props} style={{ textDecoration: "none" }}>
        <Box className={classes.link}>{props.children}</Box>
      </CustomLink>
    ) : (
      props.children
    )
  }

  const isXS = useMediaQuery(theme.breakpoints.down("xs"))

  const backgroundColor = useColor(props.background)?.background

  // console.log("CARD", props)

  return (
    <Grid item style={{ width: `${useResponsiveWidth(props.width)}%` }}>
      {/* <ScrollReveal> */}
      <HoverLink {...props}>
        <Box
          boxShadow={props.transparent ? 0 : 20}
          overflow="hidden"
          style={{
            // backgroundColor: backgroundColor,
            backgroundColor: props.transparent
              ? "transparent"
              : backgroundColor,
            color: useColor(props.background)?.text,
          }}
          height="100%"
          // minHeight="200px"
        >
          {props.displayImage !== false &&
          props.imagePosition === "BACKGROUND" ? (
            <BackgroundImage {...props} />
          ) : props.displayImage !== false &&
            (props.imagePosition === "LEFT" ||
              props.imagePosition === "RIGHT") &&
            !isXS ? (
            <HorizontalImage {...props} />
          ) : props.displayImage !== false &&
            (props.imagePosition === "TOP" || (props.imagePosition && isXS)) ? (
            <VerticalImage {...props} />
          ) : (
            <HiddenImage {...props} />
          )}
        </Box>
      </HoverLink>
      {/* </ScrollReveal> */}
    </Grid>
  )
}

export default Card
